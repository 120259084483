import * as React from "react";
import Redirect from "../../components/redirect";
//--------------------------------------------------

  const url = "https://s3.console.aws.amazon.com/s3/home?region=ap-southeast-1#";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => (
  <Redirect goto_url={url} />
);

export default IndexPage;
